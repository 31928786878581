@import "fonts";
$c-beige: #EDE8D9;
$c-green: #4E7E71;
$c-light-beige: #F7F4EA;

$c-connect-dark-green: #164444;
$c-connect-green: #103939;
$c-connect-light-green: #207D74;
$c-connect-grey: #BBBDBF;


$ipad-width: calc(100% - 720px);
$desktop-width: calc(100% - 1380px);

$qrcode-ipadsize: 100px;
$qrcode-desktopsize: 150px;
$member-pading-ipad: 40px;
$member-pading-desktop: 50px;