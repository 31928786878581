.footer {
    background-color: $c-connect-dark-green;
    padding: 40px 30px 20px 30px;
}
.footer-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
    }
    p, a {
        text-align: center;
        font-size: 8px;
        @include media-breakpoint-up(md) {
            font-size: 10px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 12px;
        }
    }
    a {
        margin-right: 10px;
        color: $c-connect-grey;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        img {
            height: 35px;
        }
    }
    p {
        color: $c-connect-grey;
    }
    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0;
        flex-wrap: wrap;
        li {
            list-style-type: none;
            font-size: 10px;
            border-right: solid 1px grey;
            text-align: center;
            a {
                margin: 0 5px;
                text-align: center;
            }
            &:first-of-type {
                padding-right: 5px;
            }
            &:last-of-type {
                border-right: none;
            }
        }
    }
}