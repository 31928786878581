.search-bar {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 40px;
    display: none;
    input {
        border-radius: 40px;
        border: none;
        padding: 10px 20px;
        margin-right: 20px;
        width: calc(100% - 80px);
        background-image: url('../assets/img/search-icon.png');
        background-position: 15px center;
        background-size: 22px 22px;
        background-repeat: no-repeat;
        padding-left: 48px;
        font-size: 16px;
        line-height: 1;
        &::placeholder {
            font-style: italic;
        }
    }
    button {
        border: none;
        text-decoration: underline;
        background-color: transparent;
        font-family: 'Raleway', sans-serif;
        font-size: 14px;
        line-height: 1;
        color: black;
        &:hover {
            font-style: italic;
        }
    }
    @include media-breakpoint-up(md) {
        position: absolute;
        top: 40px;
        right: calc($ipad-width / 2);
        width: 380px;
    }
    @include media-breakpoint-up(lg) {
        top: 60px;
        right: calc($desktop-width / 2);
        width: 380px;
    }
}