body {
    background-color: $c-connect-green;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
}
h1 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: $c-green;
}
.page-content {
    min-height: 400px;
    padding: 40px 0;
    background: linear-gradient(165deg, $c-connect-dark-green 30%, $c-connect-light-green 15%);
    @include media-breakpoint-up(md) {
        min-height: calc(100vh - 400px);
        background: linear-gradient(170deg, $c-connect-dark-green 25%, $c-connect-light-green 25%);
    }
    @include media-breakpoint-up(lg) {
        background: linear-gradient(175deg, $c-connect-dark-green 38%, $c-connect-light-green 38%);
    }
    h1 {
        margin-bottom: 40px;
        color: cadetblue;
    }
    .container {
        padding: 0 30px;
        margin: auto;
        @include media-breakpoint-up(md) {
            padding: 0;
        }
        @include media-breakpoint-up(lg) {
            position: relative;
        }
    }
}
.ebusinesscard-page {    
    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}
.loading-container {
    position: static !important;
}