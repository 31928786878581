$connect-member-mobile-width: 300px;
$connect-member-desktop-height: 386px;
.tile-wrapper {
    width: 100%;
    &:hover {
        text-decoration: none;
    }
}
.aMember {
    background-color: $c-connect-green;
    padding: 0 0 20px 0;
    margin-bottom: 40px;
    width: $connect-member-mobile-width;
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0 0 0 0;
    }
    @include media-breakpoint-up(lg) {
        position: relative;
    }
    > div {
        padding: 20px;
        @include media-breakpoint-up(md) {
            padding: 30px 50px;
        }
        @include media-breakpoint-up(lg) {
            width: 100%;
            height: $connect-member-desktop-height;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        a, p{
            display: block;
            color: $c-connect-grey;
            &:hover {
                text-decoration: none;
            }
            span {
                color: $c-connect-light-green;
                font-weight: bold;
                margin-right: 10px;
            }
        }
    }
    &__photo {
        width: $connect-member-mobile-width;
        height: $connect-member-mobile-width;
        background-position: center top;
        background-size: 300px;
        background-repeat: no-repeat;
        @include media-breakpoint-up(md) {
            height: 340px;
            width: 255px;
            background-size: cover;
        }
        @include media-breakpoint-up(lg) {
            height: $connect-member-desktop-height;
            width: 290px;
        }
    }
    &__name {
        color: $c-connect-light-green;
        text-transform: uppercase;
        text-align: left;
        font-size: 30px;
        line-height: 35px;
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
        @include media-breakpoint-up(md) {
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            font-size: 40px;
            line-height: 45px;
            margin-bottom: 25px;
        }
    }
    &__title {
        color: $c-connect-grey;
        font-size: 16px;
        line-height: 25px;
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        @include media-breakpoint-up(md) {
            width: 300px;           
        }
        @include media-breakpoint-up(lg) {
            font-size: 22px;
            line-height: 25px;
            margin-bottom: 40px;
        }        
    }
    &__cta {
        font-size: 14px;
        line-height: 24px;
        word-break: break-all;
        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 25px;
        }
    }
    .qrcode-link {
        margin-top: 20px;
        img {
            width: 80px;
            @include media-breakpoint-up(lg) {
                width: 220px;
            }
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
            right: 80px;
            top: 80;
            margin-top: 0;
        }
    }
}
.team-member-detail-wrapper {
    padding: 0;
    width: 300px;
    margin: auto;
    background-color: $c-connect-green;
    padding: 25px 20px;
    padding-top: calc(300px + 25px);
    position: relative;
    @include media-breakpoint-up(md) {
        width: 90%;
        padding: 0;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    .details-container {
        padding-bottom: 10px;
        @include media-breakpoint-up(md) {
            padding: 35px;
            padding-left: calc(300px + 35px);
            padding-bottom: 35px;
        }
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .general-info {
            margin-bottom: 30px;
            @include media-breakpoint-up(md) {
                margin-bottom: 15px;
            }
            @include media-breakpoint-up(lg) {
                width: 50%;
            }
            .name {
                color: $c-connect-light-green;
                text-transform: uppercase;
                text-align: left;
                font-size: 30px;
                line-height: 35px;
                font-family: 'Raleway', sans-serif;
                font-weight: 800;
                width: 70%;
                @include media-breakpoint-up(md) {
                    width: 50%;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 40px;
                    line-height: 45px;
                    margin-bottom: 25px;
                }
            }
            .title, .phone, .email{
                color: $c-connect-grey;
                font-size: 16px;
                line-height: 25px;
                font-family: 'Raleway', sans-serif;
                font-weight: 500;
                @include media-breakpoint-up(lg) {
                    font-size: 22px;
                    line-height: 25px;
                }
                @include media-breakpoint-up(lg) {
                    margin-bottom: 40px;
                }
            }
            .phone, .email {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
                span {
                    color: $c-connect-light-green;
                    font-weight: 600;
                    margin-right: 10px;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
            .email {
                word-break: break-all;
            }
        }
        .all-button-wrapper{
            position: relative;
            @include media-breakpoint-up(lg) {
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 70px;
            }
            .copied {
                font-size: 12px;
                line-height: 1;
                background-color: $c-connect-grey;
                padding: 10px;
                width: 100%;
                opacity: 0;
                position: absolute;
                animation: myFadeIn 3s;
                text-align: center !important;
                color: $c-connect-dark-green;
                bottom: -50px;
                @include media-breakpoint-up(lg) {
                    bottom: -40px;
                    font-size: 16px;
                }            
            }
            
            @keyframes myFadeIn {
                0% { opacity: 0;}
                50% { opacity: 0.9;}
                100% { opacity: 0;}
            }
        }
        .cta-wrapper {
            margin-bottom: 30px;
            width: 100%;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            @include media-breakpoint-up(md) {
                margin-bottom: 20px;
            }        
            a {
                color: $c-connect-grey;
                font-weight: bold;
                font-size: 14px;
                line-height: 1;
                background-color: $c-connect-light-green;
                text-align: center;
                padding: 12px 20px;
                width: 120px;
            }
        }
        .button-wrapper {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include media-breakpoint-up(md) {
                margin-bottom: 0;    
            }
            @include media-breakpoint-up(lg) {
                width: 100%;
                margin-top: 20px;
            }
            a {
                width: 100%;
                text-align: left;
                color: $c-connect-grey;
                font-weight: bold;
                font-size: 14px;
                line-height: 1;
                padding: 15px 0;
                border-top: solid 1px $c-connect-light-green;
                @include media-breakpoint-up(md) {
                    padding: 10px 0;  
                }
                &:last-child {
                    border-bottom: solid 1px $c-connect-light-green;
                }
                &:hover {
                    cursor: pointer;
                }
                img {
                    float: right;
                }
            }
        }
    }
    .photo {
        height: 300px;
        width: 100%;
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        @include media-breakpoint-up(md) {
            left: 0;
            width: 300px;
            height: 100%;
            background-position: top;
            background-size: contain;
        }
    }
    .map-container {
        height: 200px;
        @include media-breakpoint-up(md) {
            height: 300px;
        }
        .map-info {
            p, a {
                color: $c-connect-dark-green;
                display: block;
                margin-bottom: 8px;
            }
            a {
                text-decoration: underline;
            }
        }
    }
}
.back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    p {
        margin: 0 0 0 10px;
        color: $c-connect-grey;
        &:hover {
            text-decoration: none;
        }
    }
    span {
        color: $c-connect-grey;
    }
    &:hover {
        text-decoration: none;
    }
}