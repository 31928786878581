.header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $c-connect-dark-green;
    padding: 40px 0;
}
.header {
    justify-content: center;
    img {
        width: 150px;
        margin: auto;
    }
    .container {
        text-align: center;
        @media(min-width: 1920px) {
            max-width: 1380px;
            position: relative;
        }
    }
    @include media-breakpoint-up(md) {
        padding: 80px 0;
        img {
            width: 200px;
        }
    }
    @include media-breakpoint-up(lg) {
        img {
            width: 250px;
        }
    }
    
}